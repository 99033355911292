import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../../Sass/Components/_Quizz.scss';
import ProgressBar from './ProgressBar';
import Logo from '../../Img/MinArm_Logo.svg';
import Back from '../../Img/Back.png';
import Drawer from 'react-drag-drawer';
import Fade from 'react-reveal/Zoom';
import Wobble from 'react-reveal/Wobble';
import Tada from 'react-reveal/Tada';
import Confetti from 'react-confetti'


class Q4 extends Component {

  constructor(props){
    super(props);
      this.state = { 
        percentage: 40,
        open: false,
        open2: false
      };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  toggle2 = () => {
    this.setState({ open2: !this.state.open2 });
  };
    
    renderContent(){
      return( <div>{this.state.open2 ? (
        <Confetti/>
      ) : console.log("erreur confetti")}
        <section className="Quizz conteneur">        
          <header id="logo">
            <Link to="/question3" id="retour">
                <img src={Back} alt="retour"/>
            </Link>
              <img src={Logo} alt="logo"/>
          </header>       
          
          <main>
            <section id="top">
                <h1>Question 4</h1>    
                <Fade top>
                  <h2>Combien de civils sont recrutés chaque année par le ministère des armées ?</h2>
                </Fade>

                <Drawer
                  open={this.state.open}
                  onRequestClose={this.toggle}
                  allowClose={true}
                  modalElementClass="modal"
                  containerElementClass="div"
                  parentElement={document.body}
                  direction="top"
                >
                  <div className="modal2 modal-red">
                    <Wobble>
                    <h3>Mauvaise réponse</h3>
                    </Wobble>
                      <p>
                      Les recrutements de personnels civils sont en hausse depuis plusieurs années au sein du ministère des armées. Chaque année, plus de 5 000 postes sont à pourvoir dans différentes filières.
                      <br></br>
                      <br></br>
                      Actuellement, les efforts de recrutement sont orientés à destination des filières prioritaires (systèmes d’Information et de communication, sécurité des systèmes d’information, cyberdéfense, renseignement, maintien en condition opérationnelle des matériels) et en tension (infrastructure, finances, achats publics).
                      </p>
                      <br></br>
                  <br></br>
                  <Link className="link-modal" onClick={this.toggle}>Essayez à nouveau</Link>
                  
                  </div>
                  <button onClick={this.toggle} className="close">X</button>
                </Drawer>

                <Drawer
                    open={this.state.open2}
                    onRequestClose={this.toggle2}
                    allowClose={true}
                    modalElementClass="modal"
                    containerElementClass="div"
                    parentElement={document.body}
                    direction="top"
                  >
                    <div className="modal2 modal-green">
                      <Tada delay={400}>
                      <h3>Bonne réponse</h3>
                      </Tada>
                      <p>
                      Les recrutements de personnels civils sont en hausse depuis plusieurs années au sein du ministère des armées. Chaque année, plus de 5 000 postes sont à pourvoir dans différentes filières.
                      <br></br>
                      <br></br>
                      Actuellement, les efforts de recrutement sont orientés à destination des filières prioritaires (systèmes d’Information et de communication, sécurité des systèmes d’information, cyberdéfense, renseignement, maintien en condition opérationnelle des matériels) et en tension (infrastructure, finances, achats publics).
                      </p>
                      <br></br>
                      <br></br>
                          <Link to="/question5" className="link-modal">Question suivante !</Link>
                    </div>
                    <button onClick={this.toggle2} className="close">X</button>
                  </Drawer>
            </section>

            <section id="center">
                <Link className="link" onClick={this.toggle2}>Environ 5 000</Link>
                    <br></br>
                <Link className="link centered" to="/question4" onClick={this.toggle}>Environ 2 000</Link>
                    <br></br>
                <Link className="link" to="/question4" onClick={this.toggle}>Environ 1 000</Link>
            </section>
          </main>

          <footer id="bottom">
              <ProgressBar percentage={this.state.percentage}/>
          </footer>
        </section>
      </div>
      )
    }

    render(){
      return this.renderContent();
    }
}

export default Q4