import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../../Sass/Components/_Quizz.scss';
import ProgressBar from './ProgressBar';
import Logo from '../../Img/MinArm_Logo.svg';
import Back from '../../Img/Back.png';
import Drawer from 'react-drag-drawer';
import Fade from 'react-reveal/Zoom';
import Wobble from 'react-reveal/Wobble';
import Tada from 'react-reveal/Tada';
import Confetti from 'react-confetti'


class Q8 extends Component {

  constructor(props){
    super(props);
      this.state = { 
        percentage: 80,
        open: false,
        open2: false
      };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  toggle2 = () => {
    this.setState({ open2: !this.state.open2 });
  };
    
    renderContent(){
      return(
        <div>{this.state.open2 ? (
          <Confetti/>
        ) : console.log("erreur confetti")}
        <section className="Quizz conteneur">        
          <header id="logo">
            <Link to="/question7" id="retour">
                <img src={Back} alt="retour"/>
            </Link>
              <img src={Logo} alt="logo"/>
          </header>       
          
          <main>
            <section id="top">
                <h1>Question 8</h1>    
                <Fade top>
                  <h2>Le ministère des armées recrute-t-il des apprentis ?</h2>
                </Fade>

                <Drawer
                  open={this.state.open}
                  onRequestClose={this.toggle}
                  allowClose={true}
                  modalElementClass="modal"
                  containerElementClass="div"
                  parentElement={document.body}
                  direction="top"
                >
                  <div className="modal2 modal-red">
                    <Wobble>
                    <h3>Mauvaise réponse</h3>
                    </Wobble>
                     <p>
                      Le ministère des armées recrute des apprentis dans différentes spécialités professionnelles (systèmes d’information et de communication, ressources humaines ; administration, infrastructure, Maintien en condition opérationnelle, restauration hôtellerie loisirs, système de forces et d’armement….) quel que soit le niveau de diplôme (de CAP à Master). 
                      <br></br>
                      <br></br>
                      L’ensemble des offres sont disponibles sur le site Pass : https://www.fonction-publique.gouv.fr/score/pass (puis sélectionnez Ministère des Armées).  
                     </p>
                     <br></br>
                  <br></br>
                  <Link className="link-modal" onClick={this.toggle}>Essayez à nouveau</Link>
                  
                  </div>
                  <button onClick={this.toggle} className="close">X</button>
                </Drawer>

                <Drawer
                    open={this.state.open2}
                    onRequestClose={this.toggle2}
                    allowClose={true}
                    modalElementClass="modal"
                    containerElementClass="div"
                    parentElement={document.body}
                    direction="top"
                  >
                    <div className="modal2 modal-green">
                      <Tada delay={400}>
                      <h3>Bonne réponse</h3>
                      </Tada>
                      <p>
                      Le ministère des armées recrute des apprentis dans différentes spécialités professionnelles (systèmes d’information et de communication, ressources humaines ; administration, infrastructure, Maintien en condition opérationnelle, restauration hôtellerie loisirs, système de forces et d’armement….) quel que soit le niveau de diplôme (de CAP à Master). 
                      <br></br>
                      <br></br>
                      L’ensemble des offres sont disponibles sur le site Pass : https://www.fonction-publique.gouv.fr/score/pass (puis sélectionnez Ministère des Armées).  
                     </p>
                      <br></br>
                      <br></br>
                          <Link to="/question9" className="link-modal">Question suivante !</Link>
                    </div>
                    <button onClick={this.toggle2} className="close">X</button>
                  </Drawer>
            </section>

            <section id="center3">
                <Link className="link centered3" onClick={this.toggle2}>Oui</Link>
                    <br></br>
                <Link className="link" to="/question8" onClick={this.toggle}>Non</Link>
            </section>
          </main>

          <footer id="bottom">
              <ProgressBar percentage={this.state.percentage}/>
          </footer>
        </section>
      </div>
      )
    }

    render(){
      return this.renderContent();
    }
}

export default Q8