import React from 'react';
import Rooter from './Util/Rooter'
import '../src/Sass/Components/_Svg.scss'

function App() {
  return (
    <React.Fragment>
      <section id="main">
        <svg className="bleu" xmlns="http://www.w3.org/2000/svg" width="174.794" height="204.902" viewBox="0 0 174.794 204.902">
        
        <path d="M-2442.5,2140.9h-.006a59.673,59.673,0,0,1-7.49-.475V1936h165.131c13.068,27.534,12.881,54.109-.554,78.988a118.948,118.948,0,0,1-21.715,28.157,177.449,177.449,0,0,1-25.095,20.228,218.286,218.286,0,0,0-45.126,40.558c-8.775,10.413-18.04,18.8-27.54,24.936C-2417.267,2136.852-2429.919,2140.9-2442.5,2140.9Z" transform="translate(2450 -1936)"/>
        </svg>

        <Rooter/>

          <svg className="rouge" xmlns="http://www.w3.org/2000/svg" width="187.396" height="315.005" viewBox="0 0 187.396 315.005">
            
            <path d="M-2147,3400h-169.4c9.644-19.361,23.615-41.658,41.522-66.276a76.421,76.421,0,0,0,7-11.67,76.593,76.593,0,0,0,4.619-12.114,94.272,94.272,0,0,0,3.466-25.23c.2-17.156-3.215-34.946-6.521-52.151l0-.009c-1.8-9.373-3.663-19.065-4.84-28.283a126.582,126.582,0,0,1-.975-26.733,64.19,64.19,0,0,1,6.631-24.275,57.376,57.376,0,0,1,7.336-10.935,67.988,67.988,0,0,1,10.64-9.983,143.464,143.464,0,0,1,41.828-22.091,143.1,143.1,0,0,1,45.074-7.255c4.527,0,9.109.216,13.616.643V3400Z" transform="translate(2325.4 -3097)"/>
          </svg>
      </section>
      <p id="copyright">&copy; 2020 SGA/DRHMD/SRHC</p>
    </React.Fragment>
  );
}

export default App;
