import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../../Sass/Components/_Quizz.scss';
import ProgressBar from './ProgressBar';
import Logo from '../../Img/MinArm_Logo.svg';
import Back from '../../Img/Back.png';
import Fade from 'react-reveal/Zoom';

class Merci extends Component {

    constructor(props){
      super(props);
        this.state = { 
          percentage: 100,
          open: false
        };
    }

    toggle = () => {
      this.setState({ open: !this.state.open });
    };
    
    renderContent(){
      return(
        <section className="Quizz conteneur">        
          <header id="logo">
            <Link to="/question9" id="retour">
                <img src={Back} alt="retour"/>
            </Link>
              <img src={Logo} alt="logo"/>
          </header>       
          
          <main>
            <section id="top">
                <h1>Merci</h1>    
                <Fade top>
                  <h2>d’avoir accepté de participer à ce quizz !</h2>
                </Fade>
            </section>

            <section id="center">
                <Link className="link" to="/" onClick={this.toggle}>Recommencer</Link>
            </section>
          </main>

          <footer id="bottom">
              <ProgressBar percentage={this.state.percentage}/>
          </footer>
        </section>
      )
    }

    render(){
      return this.renderContent();
    }
}

export default Merci