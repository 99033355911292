import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../../Sass/Components/_Quizz.scss';
import ProgressBar from './ProgressBar';
import Logo from '../../Img/MinArm_Logo.svg';
import Back from '../../Img/Back.png';
import Drawer from 'react-drag-drawer';
import Fade from 'react-reveal/Zoom';
import Wobble from 'react-reveal/Wobble';
import Tada from 'react-reveal/Tada';

import Confetti from 'react-confetti'

class Q6 extends Component {

  constructor(props){
    super(props);
      this.state = { 
        percentage: 60,
        open: false,
        open2: false
      };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  toggle2 = () => {
    this.setState({ open2: !this.state.open2 });
  };
    
    renderContent(){
      return(
        <div>{this.state.open2 ? (
          <Confetti/>
        ) : console.log("erreur confetti")}
        <section className="Quizz conteneur">        
          <header id="logo">
            <Link to="/question5" id="retour">
                <img src={Back} alt="retour"/>
            </Link>
              <img src={Logo} alt="logo"/>
          </header>       
          
          <main>
            <section id="top">
                <h1>Question 6</h1>    
                <Fade top>
                  <h2>Dans quel(s) type(s) d’activité(s) les civils<br></br>travaillent-ils au sein du ministère des armées ?</h2>
                </Fade>

                <Drawer
                  open={this.state.open}
                  onRequestClose={this.toggle}
                  allowClose={true}
                  modalElementClass="modal"
                  containerElementClass="div"
                  parentElement={document.body}
                  direction="top"
                >
                  <div className="modal2 modal-red">
                    <Wobble>
                    <h3>Mauvaise réponse</h3>
                    </Wobble>
                      <p>
                      Le ministère des armées offre une très grande diversité de métiers dans l’ensemble des filières. Quelques exemples :
                      <br></br>
                      <br></br>
                      Filière administrative  (Droit, finances, achats, ressources humaines, gestion du patrimoine, archives, communication,…) :  juriste, chargé(e) de missions, chargé(e) d’études, gestionnaire RH, rédacteur/trice, archiviste, chargé(e) de communication,…

                      <br></br>
                      <br></br>

                      Technique (Informatique, génie civil, mécanique, électronique, télécommunications, cyber-défense, renseignement, maintien en condition opérationnelle, prévention,… ) : agent technique, agent de maîtrise, technicien(ne), ingénieur(e), chef(fe) de projet, chargé(e) de maintenance…

                      <br></br>
                      <br></br>

                      Paramédicale : cadres et techniciens de santé, aides-soignants, agents de services hospitaliers, infirmiers.
                      
                      <br></br>
                      <br></br>
                      
                      Sociale : assistante sociale.
                      </p>
                      <br></br>
                  <br></br>
                  <Link className="link-modal" onClick={this.toggle}>Essayez à nouveau</Link>
                  
                  </div>
                  <button onClick={this.toggle} className="close">x</button>
                </Drawer>

                <Drawer
                    open={this.state.open2}
                    onRequestClose={this.toggle2}
                    allowClose={true}
                    modalElementClass="modal"
                    containerElementClass="div"
                    parentElement={document.body}
                    direction="top"
                  >
                    <div className="modal2 modal-green">
                      <Tada delay={400}>
                      <h3>Bonne réponse</h3>
                      </Tada>
                      <p>
                      Le ministère des armées offre une très grande diversité de métiers dans l’ensemble des filières. Quelques exemples :
                      <br></br>
                      <br></br>
                      Filière administrative  (Droit, finances, achats, ressources humaines, gestion du patrimoine, archives, communication,…) :  juriste, chargé(e) de missions, chargé(e) d’études, gestionnaire RH, rédacteur/trice, archiviste, chargé(e) de communication,…

                      <br></br>
                      <br></br>

                      Technique (Informatique, génie civil, mécanique, électronique, télécommunications, cyber-défense, renseignement, maintien en condition opérationnelle, prévention,… ) : agent technique, agent de maîtrise, technicien(ne), ingénieur(e), chef(fe) de projet, chargé(e) de maintenance…

                      <br></br>
                      <br></br>

                      Paramédicale : cadres et techniciens de santé, aides-soignants, agents de services hospitaliers, infirmiers.
                      
                      <br></br>
                      <br></br>
                      
                      Sociale : assistante sociale.
                      </p>
                      <br></br>
                      <br></br>
                          <Link to="/question7" className="link-modal">Question suivante !</Link>
                    </div>
                    <button onClick={this.toggle2} className="close">X</button>
                  </Drawer>
            </section>

            <section id="center2">
                <Link className="link2" to="/question6" onClick={this.toggle}>Dans le domaine administratif (finances, droit..etc..)</Link>
                    <br></br>
                <Link className="link2" to="/question6" onClick={this.toggle}>Dans le domaine technique (informatique, mécanique…etc..)</Link>
                    <br></br>
                <Link className="link2" to="/question6" onClick={this.toggle}>Dans le domaine paramédical (infirmier..etc..)</Link>
                    <br></br>
                <Link className="link2" to="/question6" onClick={this.toggle}>Dans le social (assistante sociale)</Link>
                    <br></br>
                <Link className="link2" onClick={this.toggle2}>Dans l’ensemble de ces domaines</Link>
            </section>
          </main>

          <footer id="bottom">
              <ProgressBar percentage={this.state.percentage}/>
          </footer>
        </section>
      </div>
      )
    }

    render(){
      return this.renderContent();
    }
}

export default Q6