import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../../Sass/Components/_Quizz.scss';
import Logo from '../../Img/MinArm_Logo.svg';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

class Quizz extends Component {
    render(){
        return(
            <section className="Quizz conteneur">        
              <header id="logo">
                  <img src={Logo} alt="logo"/>
              </header>       
              
              <main>
                <section id="top">
                    <h1>Bienvenue !</h1>    
                    <h2>Testez vos connaissances sur le recrutement du personnel<br></br>civil et des apprentis au ministère des armées</h2>

                    <br></br>
                    <p id="UneBonneReponse">Une seule bonne réponse par question</p>
                </section>
    
                <section id="center">
                    <Link className="link" to="/question1">Commencer le Quizz !</Link>

                </section>
              </main>
            </section>
          )
    }
}

export default Quizz;