import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Quizz from '../Components/Quizz/Quizz'
import Q1 from '../Components/Quizz/Q1'
import Q2 from '../Components/Quizz/Q2'
import Q3 from '../Components/Quizz/Q3'
import Q4 from '../Components/Quizz/Q4'
import Q5 from '../Components/Quizz/Q5'
import Q6 from '../Components/Quizz/Q6'
import Q7 from '../Components/Quizz/Q7'
import Q8 from '../Components/Quizz/Q8'
import Q9 from '../Components/Quizz/Q9'
import Merci from '../Components/Quizz/Merci'
import '../Sass/Components/_reset.scss';

class Rooter extends Component {
    render(){
        const Root = () => (
            <Switch>
                <Route exact path="/" component={Quizz} />
                <Route exact path="/question1" component={Q1} />
                <Route exact path="/question2" component={Q2} />
                <Route exact path="/question3" component={Q3} />
                <Route exact path="/question4" component={Q4} />
                <Route exact path="/question5" component={Q5} />
                <Route exact path="/question6" component={Q6} />
                <Route exact path="/question7" component={Q7} />
                <Route exact path="/question8" component={Q8} />
                <Route exact path="/question9" component={Q9} />
                <Route exact path="/merci" component={Merci} />
            </Switch>
        )

        return(
            <Router>
                <Root/>
            </Router>
        )
    }
}

export default Rooter;