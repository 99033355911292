import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../../Sass/Components/_Quizz.scss';
import ProgressBar from './ProgressBar';
import Logo from '../../Img/MinArm_Logo.svg';
import Back from '../../Img/Back.png';
import Drawer from 'react-drag-drawer';
import Fade from 'react-reveal/Zoom';
import Wobble from 'react-reveal/Wobble';
import Tada from 'react-reveal/Tada';


import Confetti from 'react-confetti'

class Q1 extends Component {
    
    constructor(props){
        super(props);
          this.state = { 
            percentage: 10,
            open: false,
            open2: false
          };
      }

  
      toggle = () => {
        this.setState({ open: !this.state.open });
      };

      toggle2 = () => {
        this.setState({ open2: !this.state.open2 });
      };
      
      renderContent(){
        
        return(
          <div>{this.state.open2 ? (
            <Confetti/>
          ) : console.log("erreur confetti")}
          <section className="Quizz conteneur">   

            

            <header id="logo">
              <Link to="/" id="retour">
                  <img src={Back} alt="retour"/>
              </Link>
                <img src={Logo} alt="logo"/>
            </header>       
            
            <main>
              <section id="top">
                  <h1>Question 1</h1>    
                  <Fade top>
                    <h2>Qui travaille au ministère des armées ?</h2>
                  </Fade>
  
                  <Drawer
                    open={this.state.open}
                    onRequestClose={this.toggle}
                    allowClose={true}
                    modalElementClass="modal"
                    containerElementClass="div"
                    parentElement={document.body}
                    direction="top"
                  >
                    <div className="modal2 modal-red">
                      <Wobble>
                      <h3>Mauvaise réponse</h3>
                      </Wobble>
                        <p>
                          Les civils sont une composante essentielle au Ministère des armées dont l'un des principes fondamentaux, inhérent à l'identité du ministère, est la complémentarité  entre le personnel  civil et les militaires.
                        </p>
                        <br></br>
                        <br></br>
                          <Link className="link-modal" onClick={this.toggle}>Essayez à nouveau</Link>
                    </div>
                    <button onClick={this.toggle} className="close">X</button>
                  </Drawer>

                  <Drawer
                    open={this.state.open2}
                    onRequestClose={this.toggle2}
                    allowClose={true}
                    modalElementClass="modal"
                    containerElementClass="div"
                    parentElement={document.body}
                    direction="top"
                  >
                  

                    <div className="modal2 modal-green">
                      <Tada delay={400}>
                      <h3>Bonne réponse</h3>
                      </Tada>
                        <p>
                          Les civils sont une composante essentielle au Ministère des armées dont l'un des principes fondamentaux, inhérent à l'identité du ministère, est la complémentarité  entre le personnel  civil et les militaires.
                        <br></br>
                        <br></br>
                          <Link to="/question2" className="link-modal">Question suivante !</Link>

                        </p>
                    </div>
                    <button onClick={this.toggle2} className="close">X</button>
                  </Drawer>
              </section>
  
              <section id="center">
                  <Link className="link" to="/question1" onClick={this.toggle}>Uniquement des Militaires</Link>
                      <br></br>
                  <Link className="link centered" to="/question1" onClick={this.toggle}>Uniquement des Civils</Link>
                      <br></br>
                  <Link className="link" to="/question1" onClick={this.toggle2}>Des militaires et des civils</Link>
              </section>
            </main>
  
            <footer id="bottom">
                <ProgressBar percentage={this.state.percentage}/>
            </footer>
          </section>
        </div>
        )
      }
  
      render(){
        return this.renderContent();
      }
}

export default Q1